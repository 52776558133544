import { retryEvent } from "@/utils/retryEvent";

export const unbxdTrackMultipleEvent = (name: string, data: any) => {
  retryEvent(
    `unbxdTrackMultiple: ${name}`,
    () => window.Unbxd && typeof window.Unbxd.track === "function",
    () => window.Unbxd.trackMultiple(name, data)
  );
};

export const unbxdTrackEvent = (name: string, data: any) => {
  retryEvent(
    `unbxdTrack: ${name}`,
    () => window.Unbxd && typeof window.Unbxd.track === "function",
    () => window.Unbxd.track(name, data)
  );
};

export const unbxdCusEngTrackEvent = (name: string, data: any) => {
  retryEvent(
    `unbxdCusEngTrack: ${name}`,
    () => window.smartech && typeof window.smartech === "function",
    () => {
      console.log("smartech dispatch", name, data);
      window.smartech("dispatch", name, data);
    }
  );
};
export const unbxdCusEngTrackUser = (data: any) => {
  retryEvent(
    `unbxdCusEngTrack: contact`,
    () => window.smartech && typeof window.smartech === "function",
    () => {
      console.log("smartech dispatch user", data);
      window.smartech("contact", 1, data);
    }
  );
};
