import endpoints from "@/api/endpoints";
import httpClient from "@/api/http-client";
import { CartDiscount, CartItem } from "@/models/cart";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { hasCookie } from "cookies-next";
import { AppState } from "..";

export interface CartState {
  items_loading: boolean;
  sub_total: number;
  discounts?: any;
  total: number;
  final_amount: number;
  items: CartItem[];
  discount?: CartDiscount;
  dg_code?: string;
  dg_discount?: number;
  is_pancard_required: boolean;
  is_billing_address_required: boolean;
}

const initialState: CartState = {
  items_loading: true,
  total: 0,
  final_amount: 0,
  items: [],
  sub_total: 0,
  is_pancard_required: false,
  is_billing_address_required: false,
};

export const fetchCartItems = createAsyncThunk(
  "cart/fetchCartItems",
  async () => {
    if (!hasCookie("token")) {
      return;
    }
    const { data } = await httpClient.get(endpoints.cart.items());
    return data;
  }
);

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setItems: (state, action: PayloadAction<CartState["items"]>) => {
      state.items = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCartItems.pending, (state, action) => {
        state.items_loading = true;
      })
      .addCase(fetchCartItems.fulfilled, (state, action) => {
        state.items_loading = false;
        if (!action.payload || typeof action.payload != "object") {
          return;
        }

        state.items = action.payload.items;
        state.discounts = action.payload.discounts;
        state.total = action.payload?.total;
        state.sub_total = action.payload?.sub_total;
        state.is_pancard_required = action.payload?.is_pancard_required;
        state.is_billing_address_required =
          action.payload?.is_billing_address_required;

        state.final_amount = action.payload.data?.final_amount;
        state.dg_code = action.payload.data?.dg_code;
        state.dg_discount = action.payload.data?.dg_discount;
      })
      .addCase(fetchCartItems.rejected, (state, action) => {
        state.items_loading = false;
      });
  },
});

export const cartActions = cartSlice.actions;

export const selectCart = (state: AppState) => state.cart;

export default cartSlice.reducer;
